import Vue from 'vue';

export default {
    getRoles(parametros){
        return Vue.axios.get("roles/find",{ params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getRol(parametros){
        return '';
    },
    updateRol(parametros){

    },
    deleteRol(parametros){

    },
    addRol(parametros){
        
    }
}

