<template>
    <div id="pageTable">
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay>
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa' && rol != 'admin-sucursal' && rol != 'admin-depto'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Usuarios'"
                        :url="url"
                        :columns="columns"
                        @loadModalData="loadModalData"
                        :filters="filters"
                        @setFilters="setFilters"
                        :perPage="[10, 25, 50, 100]"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abreModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                            
                            <v-menu offset-y content-class="menuClassAcciones" v-if="rol=='root'|| rol=='admin' || rol=='admin-empresa' || rol=='admin-sucursal'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        class="btnAcciones pr-1 mr-3"
                                        v-bind="attrs"
                                        v-on="on"
                                        elevation="0"
                                    >
                                        Acciones 
                                        <template  v-for="(item,valor) in attrs" >
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#FFFFFF'>expand_less</v-icon>
                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#FFFFFF'>expand_more</v-icon>
                                        </template>
                                    </v-btn>
                                    
                                </template>
                                <div class="btnAccionesContenedor">
                                    <div @click="abrirModalReporte()" class="btnAccionesItem py-2">
                                        <span>Descargar reporte</span>
                                    </div>
                                </div>
                            </v-menu>
                        </template>
                        <template slot="filters">
                            <template v-if="rol == 'root'">
                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="clientes_search"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Cliente"
                                        persistent-hint
                                        v-model="cliente_value_search"
                                    ></v-autocomplete>
                                </v-col>
                            </template>

                            <template v-if="(rol == 'root' || rol == 'admin')">
                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="empresa_search"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Empresa"
                                        persistent-hint
                                        v-model="empresa_value_search"
                                    ></v-autocomplete>
                                </v-col>
                            </template>

                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="sucursal_search"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Sucursal"
                                        persistent-hint
                                        v-model="sucursal_value_search"
                                    ></v-autocomplete>
                                </v-col>
                            </template>

                            <template
                                v-if="
                                    rol == 'root' ||
                                    rol == 'admin' ||
                                    rol == 'admin-empresa' ||
                                    rol == 'admin-sucursal'
                                "
                            >
                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="departamento_search"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Departamento"
                                        persistent-hint
                                        v-model="departamento_value_search"
                                    />
                                </v-col>
                            </template>
                            <template>
                                <v-col xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="roles_search"
                                        item-text="display_name"
                                        item-value="id"
                                        label="Rol"
                                        persistent-hint
                                        v-model="rol_value_search"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            <v-col
                                cols="12"
                                xs="12"
                                sm="12"
                                md="12"
                                class="py-0"
                            >
                                <v-text-field
                                    outlined
                                    label="Correo"
                                    v-model="correo_search"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                xs="12"
                                sm="12"
                                md="12"
                                class="py-0"
                            >
                                <v-text-field
                                    outlined
                                    label="Nombre"
                                    v-model="nombreUsuario"
                                ></v-text-field>
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td>
                                    <div class="tblPrincipal d-flex">
                                        <v-avatar>
                                            <img :src="urlImg+item.profile_picture" width="130px" />
                                        </v-avatar>

                                        <div class="my-auto ml-2">
                                            {{ item.nombre }} {{ item.apellido_paterno }}
                                            <div class="tblSecundario">{{ item.email }}</div>
                                        </div>
                                    </div>
                                    
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ item.nombre_rol }}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ item.nombre_empresa }}</div>
                                </td>
                                <td style="min-width:290px!important; max-width:301px!important; width:300px; padding-left:8px; padding-right:8px;">
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    slot="activator"
                                                    class="botonHover"
                                                    icon
                                                    fab
                                                    dark
                                                    small
                                                    @click="abreModal('update', item, 'true')"
                                                >
                                                    <v-icon class="tamIconoBoton invertirColorBotones">lock</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Restaurar contraseña</span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="item.rol_id != 7">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    slot="activator"
                                                    class="botonHover"
                                                    icon
                                                    fab
                                                    dark
                                                    @click="openModalMenu(item)"
                                                    small
                                                >
                                                    <v-icon class="tamIconoBoton invertirColorBotones">list</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Privilegios</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn 
                                                    v-on="on"
                                                    class="botonHover" 
                                                    icon 
                                                    fab 
                                                    dark 
                                                    small
                                                    @click="abreModal('update', item)"
                                                >
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn 
                                                    v-on="on" 
                                                    class="botonHover" 
                                                    icon 
                                                    fab 
                                                    dark 
                                                    small
                                                    @click="eliminar(item.id, item.email)"
                                                >
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>
        <template>
            <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnmodal"></v-btn>
                </template>
                <perfect-scrollbar style="background:#FFF">
                    <v-card elevation="0">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ titulo_modal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="!finaliza_carga">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="finaliza_carga" grid-list-md id="contenedor">
                                     
                                    <v-divider class="divider"></v-divider>
                                    
                                    <v-form ref="form">
                                        <v-row>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        autofocus
                                                        label="Nombre(s)"
                                                        v-model="usuario.nombre"
                                                        autocomplete="off"
                                                        :error-messages="errors"
                                                        auto
                                                        required
                                                    />
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Apellido paterno" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        label="Apellido paterno"
                                                        v-model="usuario.apellido_paterno"
                                                        required
                                                        autocomplete="off"
                                                        :error-messages="errors"
                                                        auto
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                                                <v-text-field
                                                    outlined
                                                    label="Apellido materno"
                                                    v-model="usuario.apellido_materno"
                                                    autocomplete="off"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Teléfono" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        label="Teléfono"
                                                        v-model="usuario.telefono"
                                                        v-mask="'(###) ###-####'"
                                                        :error-messages="errors"
                                                        auto
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                                                <v-text-field
                                                    outlined
                                                    label="Teléfono de oficina"
                                                    v-mask="'(###) ###-####'"
                                                    v-model="usuario.telefono_oficina"
                                                    autocomplete="off"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Email" rules="email">
                                                    <v-text-field
                                                        outlined
                                                        label="Email"
                                                        v-model="usuario.email"
                                                        required
                                                        id="email"
                                                        autocomplete="off"
                                                        :error-messages="errors"
                                                        auto
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Usuario" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        label="Usuario"
                                                        v-model="usuario.usuario"
                                                        required
                                                        id="usuario"
                                                        autocomplete="off"
                                                        :error-messages="errors"
                                                        auto
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Rol" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="roles"
                                                        item-text="display_name"
                                                        item-value="id"
                                                        label="Rol"
                                                        persistent-hint
                                                        v-model="rol_value"
                                                        :error-messages="errors"
                                                    />
                                                </ValidationProvider>
                                            </v-col>

                                            <template
                                                v-if="
                                                    (rol_value == 7 ||
                                                    rol_value == 5 ||
                                                    rol_value == 4 ||
                                                    rol_value == 3 ||
                                                    rol_value == 2) &&
                                                    rol == 'root'
                                                    "
                                            >
                                                <v-col xs="12" sm="6" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="clientes"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Cliente"
                                                            persistent-hint
                                                            v-model="cliente_value"
                                                            :error-messages="errors"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>

                                            <template
                                                v-if="
                                                    (rol_value == 7 ||
                                                    rol_value == 5 ||
                                                    rol_value == 4 ||
                                                    rol_value == 3) &&
                                                    (rol == 'root' || rol == 'admin')
                                                    "
                                            >
                                                <v-col xs="12" sm="6" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="empresas"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Empresa"
                                                            persistent-hint
                                                            v-model="empresa_value"
                                                            :error-messages="errors"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>

                                            <template
                                                v-if="
                                                    (rol_value == 7 ||
                                                    rol_value == 4) &&
                                                    (rol == 'root' ||
                                                        rol == 'admin' ||
                                                        rol == 'admin-empresa')
                                                    "
                                            >
                                                <v-col xs="12" sm="6" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Sucursal" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="sucursales"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Sucursal"
                                                            persistent-hint
                                                            v-model="sucursal_value"
                                                            :error-messages="errors"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                            <template
                                                v-if="
                                                    (rol_value == 5) &&
                                                    (rol == 'root' ||
                                                        rol == 'admin' ||
                                                        rol == 'admin-empresa')
                                                "
                                            >
                                                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Sucursales" rules="required">
                                                        <v-select
                                                            v-model="sucursalesModel"
                                                            :items="sucursalesItems"
                                                            outlined
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Sucursal"
                                                            multiple
                                                            :error-messages="errors"
                                                        >
                                                            <template v-slot:selection="{ item, index }">
                                                                <v-chip v-if="index === 0" >
                                                                    {{
                                                                        item.nombre < 9
                                                                        ? item.nombre
                                                                        : item.nombre.substr(0, 8) + "..."
                                                                    }}
                                                                </v-chip>
                                                                <span
                                                                    v-if="index === 1"
                                                                    class="grey--text caption"
                                                                >(+{{ sucursalesModel.length - 1 }} otros)
                                                                </span>
                                                            </template>
                                                        </v-select>
                                                    </ValidationProvider>
                                                    <!-- 
                                                    <span
                                                        class="invalid-feedback color_rojo"
                                                        v-if="error_sucursal"
                                                        style="display:block"
                                                        >Es requerido al menos una sucursal.
                                                    </span> 
                                                    -->
                                                </v-col>
                                            </template>

                                            <template
                                                v-if="
                                                    rol_value == 5 &&
                                                    (rol == 'root' ||
                                                        rol == 'admin' ||
                                                        rol == 'admin-empresa' ||
                                                        rol == 'admin-sucursal')
                                                    "
                                            >
                                                <v-col xs="12" sm="6" md="6" class="py-0">
                                                    <v-select
                                                        v-model="departamentos_value"
                                                        :items="departamentos"
                                                        outlined
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Departamento"
                                                        multiple
                                                        @change="Cambio_depto()"
                                                    >
                                                        <template v-slot:selection="{ item, index }">
                                                            <v-chip v-if="index === 0" >
                                                                {{
                                                                    item.nombre < 9
                                                                    ? item.nombre
                                                                    : item.nombre.substr(0, 8) + "..."
                                                                }}
                                                            </v-chip>
                                                            <span
                                                                v-if="index === 1"
                                                                class="grey--text caption"
                                                            >(+{{ departamentos_value.length - 1 }} otros)
                                                            </span>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                                <span
                                                    class="invalid-feedback color_rojo"
                                                    v-if="error_depto"
                                                    style="display:block"
                                                    >Es requerido al menos un departamento.</span>
                                            </template>

                                            <!-- 
                                            <template
                                                v-if="
                                                    rol_value == 6 &&
                                                    (rol == 'root' ||
                                                        rol == 'admin' ||
                                                        rol == 'admin-empresa' ||
                                                        rol == 'admin-sucursal')
                                                    "
                                                >
                                                <v-col xs="12" sm="6" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Departamento" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="departamentos"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Departamento"
                                                            persistent-hint
                                                            v-model="usuario.departamento_id"
                                                            :error-messages="errors"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template> -->

                                            <template
                                                v-if="rol_value == 2 || rol_value == 3 || rol_value == 4"
                                            >
                                                <v-col xs="12" sm="6" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Dashboard" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="itemsDashboard"
                                                            item-text="nombre"
                                                            item-value="value"
                                                            label="Dashboard"
                                                            persistent-hint
                                                            v-model="usuario.tipo_dashboard"
                                                            :error-messages="errors"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>

                                            <!-- Comentado por Manuel esto debido a que ahora se manejaran los grupos
                                            <template>
                                                <v-col xs="12" sm="12" md="12" class="py-0">
                                                    <v-select
                                                        v-model="modelo_empresas"
                                                        :items="items_empresas"
                                                        outlined
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Empresas nómina"
                                                        multiple
                                                        @change="cambio_emp()"
                                                    >
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip v-if="index === 0">
                                                            <span>
                                                                {{
                                                                    item.nombre < 9
                                                                    ? item.nombre
                                                                    : item.nombre.substr(0, 8) + "..."
                                                                }}
                                                            </span>
                                                        </v-chip>
                                                        <span
                                                            v-if="index === 1"
                                                            class="grey--text caption"
                                                        >(+{{ modelo_empresas.length - 1 }} otros)
                                                        </span>
                                                    </template>
                                                    </v-select>
                                                </v-col>
                                            </template> -->

                                            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                                <v-menu offset-y elevation="0" content-class="menuClassFoto" z-index="999">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            v-model="archivo_adjunto"
                                                            outlined
                                                            label="Foto empleado"
                                                            type="text"
                                                            hide-details
                                                            readonly
                                                            class="d-flex my-0 py-0 otro-icono"
                                                        > 
                                                            <template slot="append">
                                                                <v-icon v-bind="attrs" v-on="on">arrow_drop_down</v-icon>
                                                            </template>
                                                            <template slot="append-outer">
                                                                <template v-if="accion === 'add'">
                                                                    <v-avatar class="ml-1">
                                                                        <img id="img" src="/static/avatar/usuario4.jpg" width="130px" />
                                                                    </v-avatar>
                                                                </template>
                                                                <template v-else>
                                                                    <v-avatar class="ml-1">
                                                                        <img id="img" :src="nombreFotoUptade" width="130px" />
                                                                    </v-avatar>
                                                                </template>
                                                            </template>
                                                        </v-text-field>                                                                    
                                                    </template>
                                                    <v-text-field
                                                        outlined
                                                        id="form"
                                                        readonly
                                                        label="Adjuntar desde galería"
                                                        @click="almacenarImagen"
                                                        v-model="nombreImagen"
                                                        clearable
                                                        hide-details
                                                        class="pt-2 v-text-field-foto"
                                                    >
                                                        <template slot="append">
                                                            <v-icon class="tamIcono">attach_file</v-icon>
                                                        </template>
                                                    </v-text-field>
                                                    <input
                                                        type="file"
                                                        style="display: none"
                                                        ref="imagen"
                                                        accept="image/*"
                                                        id="avatar"
                                                        @change="onFilePickedImagen"
                                                    />
                                                
                                                
                                                    <v-text-field
                                                        outlined
                                                        id="form2"
                                                        readonly
                                                        label="Tomar fotografía"
                                                        @click="abrirModalFoto('capturar')"
                                                        v-model="nombreFoto"
                                                        clearable
                                                        hide-details
                                                        class="pt-2 v-text-field-foto"
                                                    >
                                                        <template slot="append">
                                                            <v-icon class="tamIcono invertirColor" >camera_alt</v-icon>
                                                        </template>
                                                    </v-text-field>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-container>
                            </v-card-text>
                            <v-card-actions v-show="finaliza_carga" class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()"
                                    :disabled="loading_guardar"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="veeValidate()"
                                    :loading="loading_guardar"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </perfect-scrollbar>                
            </v-dialog>
        </template>

        <v-row>
            <v-dialog
                v-model="dialogTree"
                persistent
                max-width="600px"
                style="max-height: 800px;"
                scrollable
            >
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnmodalTree"></v-btn>
                </template>
                <perfect-scrollbar style="background:#FFF">
                    <v-card elevation="0">
                        <v-card-title class="justify-center card_titulo">
                            <div class="headerModal">
                                <img :src="imagen" class="imgModal" />
                                <h2 class="titleModal">{{ titulo_modal }}</h2>
                            </div>
                        </v-card-title>
                

                        <v-card-text class="justify-center card_texto">
                            <div id="loadingModal" v-show="loading">
                                <div id="loading">
                                    <v-progress-circular
                                        indeterminate
                                        :size="100"
                                        :width="7"
                                        color="blue"
                                    ></v-progress-circular>
                                </div>
                            </div>

                            <v-container grid-list-md id="contenedor" v-show="!loading">
                                
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12">
                                        <draggable 
                                            v-model="menuConfig.menuUsuario" 
                                            :options="{ group: { name: 'gf1', put: 'gf1'}, animation: 300 } " 
                                        >
                                            <template v-for="(item, index) in menuConfig.menuUsuario">
                                                <template v-if="true/*item.sistema == 'nomina'*/">
                                                    <v-layout
                                                        :key="item.id"
                                                        row
                                                        v-if="item.children.length == 0"
                                                    >
                                                        <div
                                                            style="position: relative; width: 100px; height: 60px;"
                                                        >
                                                            <input
                                                                :id="'chkP' + item.id"
                                                                type="checkbox"
                                                                class="ck-checkbox"
                                                                v-model="item.active"
                                                            />
                                                            <label :for="'chkP' + item.id" class="ck-label">
                                                                {{ item.name }}
                                                            </label>
                                                        </div>
                                                    </v-layout>

                                                    <v-layout row :key="item.id" v-else>
                                                        <div
                                                            style="position: relative; width: 100px; height: 55px;"
                                                        >
                                                            <input
                                                                :id="'chkP' + item.id"
                                                                type="checkbox"
                                                                class="ck-checkbox"
                                                                @click="_selectedAll(item, item.type, index)"
                                                                v-model="item.active"
                                                            />
                                                            <label class="ck-label" :for="'chkP' + item.id">
                                                                {{ item.name }}
                                                            </label>
                                                        </div>

                                                        <div class="checkbox-container" style="margin-top: 40px;">
                                                            <draggable 
                                                                v-model="item.children" 
                                                                :options="{ group: { name: 'gc' + item.id, put: 'gc' + item.id}, animation: 300 } " 
                                                            >
                                                                <template v-for="child in item.children">
                                                                    <div
                                                                        :key="child.id"
                                                                        style="position: relative; width: 100px; height: 40px; "
                                                                    >
                                                                        <input
                                                                            :id="'chkC' + child.id"
                                                                            class="ck-checkbox"
                                                                            type="checkbox"
                                                                            @click="_selectedAll(item, child.type, index, child)"
                                                                            v-model="child.active"
                                                                        />
                                                                        <label :for="'chkC' + child.id" class="ck-label">
                                                                            {{ child.name }}
                                                                        </label>
                                                                    </div>
                                                                </template>
                                                            </draggable>
                                                        </div>
                                                    </v-layout>
                                                </template>
                                            </template>
                                        </draggable>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-layout row>
                                <v-flex class="justify-center">
                                    <div class="btnModal">
                                        <v-btn class="btnGuardar modal-pull-right" @click="updateMenu()" :loading="loading_guardar">Guardar</v-btn>
                                        <button class="btnCerrar modal-pull-right" @click="cerrarModal()">Cancelar</button>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-card-actions>
                    </v-card>
                </perfect-scrollbar>
            </v-dialog>

            
            <v-dialog v-model="dialogReporte" persistent max-width="700px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalReporte"></v-btn>
                </template>
                <v-card class="borde-card">
                        <v-card-title class="card_titulo padding_derecho padding_izquierdo pb-5">
                            <div class="headerModal-filter">
                                <h2 class="titleModal">{{ "Descargar reporte" }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <div id="padre" v-if="isLoadingModal" class="d-flex justify-center">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-if="!isLoadingModal" grid-list-md id="contenedor">
                                <v-row class="pt-0 mt-0">
                                    <template v-if="rol == 'root'">
                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                :items="clientes_search"
                                                item-text="nombre"
                                                item-value="id"
                                                label="Cliente"
                                                persistent-hint
                                                v-model="cliente_value_search"
                                            ></v-autocomplete>
                                        </v-col>
                                    </template>

                                    <template v-if="(rol == 'root' || rol == 'admin')">
                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                :items="empresa_search"
                                                item-text="nombre"
                                                item-value="id"
                                                label="Empresa"
                                                persistent-hint
                                                v-model="empresa_value_search"
                                            ></v-autocomplete>
                                        </v-col>
                                    </template>

                                    <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                :items="sucursal_search"
                                                item-text="nombre"
                                                item-value="id"
                                                label="Sucursal"
                                                persistent-hint
                                                v-model="sucursal_value_search"
                                            ></v-autocomplete>
                                        </v-col>
                                    </template>

                                    <v-col xs="12" sm="6" md="6" class="py-0">
                                        <v-autocomplete
                                            outlined
                                            :items="roles_search"
                                            item-text="display_name"
                                            item-value="id"
                                            label="Rol"
                                            persistent-hint
                                            v-model="rol_value_search"
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModalReporte()" 
                                :disabled="loadingReporte"
                            >
                                Cerrar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="descargarReporte()"
                                :loading="loadingReporte"
                            >
                                Descargar
                            </v-btn>
                        </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog v-model="dialogFoto" persistent max-width="450px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalFoto"></v-btn>
                </template>
                <v-card>
                    <div>
                        <v-btn
                            @click="cerrarModalFoto()"
                            small
                            slot="activator"
                            icon
                            text
                            class="v-btn-cerrar modal-pull-right mr-3 mt-3"
                        >
                            <v-icon class="icono-cerrar"></v-icon>
                        </v-btn>
                    </div>
                    <v-card-title>
                        <img src="/static/icon/webcam.png" class="imgFoto" />
                        <h3 class="titulo-foto">Tomar foto</h3>
                    </v-card-title>
                    <v-card-text>
                        <template style="paddin-left:30%">
                            <v-autocomplete
                            :items="devices"
                            item-text="label"
                            item-value="deviceId"
                            class="estiloFoto py-0 my-0"
                            label="Webcam"
                            persistent-hint
                            v-model="camera"
                            >
                                <template class="pa-0 ma-0" slot="prepend-inner">
                                    <v-icon class="tamIcono invertirColor">camera_alt</v-icon>
                                    <v-icon class="fa-rotate-270 pt-3 iconoDelgado">maximize</v-icon>
                                </template>
                            </v-autocomplete>
                        </template>

                        <template v-if="imgFoto == null">
                            <div
                                class="center el-video"
                                style="position: relative; height: 250px; display: flex; align-items: center; padding: 0px 65px;"
                            >
                            <web-cam
                                id="video"
                                ref="webcam"
                                height="200"
                                width="250"
                                class="webcam"
                                :device-id="deviceId"
                                @started="onStarted"
                                @stopped="onStopped"
                                @error="onError"
                                @cameras="onCameras"
                                @camera-change="onCameraChange"
                            />
                            </div>
                        </template>
                        <template v-else>
                            <v-flex
                                class="center"
                                style="position: relative; height: 200px; display: flex; align-items: center; padding: 0px 65px;"
                            >
                                <img :src="imgFoto" style="height :200px; width: 250px" class="webcam img-responsive"/>
                            </v-flex>

                        </template>
                        <div class="col-md-12 btn-contenedor">
                            <v-btn v-if="imgFoto == null" class="btn-capturar" @click="onCapture">Capturar</v-btn>
                            <div v-else class="col-md-12 btn-contenedor">
                                <v-btn class="btn-capturar" @click="guardaFoto">Guardar</v-btn>
                                <v-btn class="btn-omitir" id="no-background-hover" @click="omitirFoto()">Tomar nueva foto</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions } from "vuex";
import Datatable from '@/components/datatable/Datatable.vue';
import userApi from "../api/usuarios";
import rolApi from "../api/roles";
import clienteAPi from "../api/clientes";
import empresaApi from "../api/empresas";
import sucursalApi from "../api/sucursales";
import departamentoApi from "../api/departamentos";
import Notify from "@/plugins/notify";
import draggable from "vuedraggable";
import GetUrlImg from "@/plugins/Urlimagen";
import { WebCam } from "vue-web-cam";

export default {
    components: {
        'data-table': Datatable,
        draggable,
        "web-cam": WebCam,
    },
    data() {
        return {
            outsourcing:false,
            url: "usuarios/find",
            columns         : [
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Rol',
                    name: 'rol',
                    filterable: true
                },
                {
                    label: 'Empresa',
                    name: 'empresa',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            usuario: {
                id                  : "",
                nombre              : "",
                apellido_paterno    : "",
                apellido_materno    : "",
                telefono_oficina    : "",
                telefono            : "",
                email               : "",
                usuario             : "",
                password            : "123456",
                cliente_id          : null,
                empresa_id          : null,
                sucursal_id         : null,
                departamento_id     : null,
                rol_id              : null,
                email_anterior      : null,
                departamentos       : [],
                usuario_empresas    : [],
                tipo_dashboard      : "payroll"
            },
            filters: {
                include_padres  : true,
                activo          : true,
                paginate        : true,
                cliente_id      : null,
                empresa_id      : null,
                sucursal_id     : null,
                departamento_id : null,
                empleado_id     : null,
                order_by        : 'nombre'
            },
            UsuariosFiltros:{},
            cliente_value_search        : null,
            empresa_value_search        : null,
            sucursal_value_search       : null,
            departamento_value_search   : null,
            rol_value_search            : null,
            correo_search               : null,

            loading_guardar     : false,
            dialog              : false,
            dialogTree          : false,
            dialogBusqueda      : false,
            loading             : true,
            datosLogin          : "",

            tableData           : {},
            isLoading           : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            imagen              : "",
            titulo_modal        : "",
            accion              : "",
            roles               : [],

            clientes_search     : [],
            empresa_search      : [],
            sucursal_search     : [],
            departamento_search : [],
            roles_search        : [],
            nombreUsuario       : null,

            rol                 : "",
            clientes            : [],
            empresas            : [],
            sucursales          : [],
            departamentos       : [],
            carga_final         : 0,
            finaliza_carga      : false,

            cliente_value       : null,
            empresa_value       : null,
            sucursal_value      : null,
            rol_value           : null,
            rol_sesion          : null,
            menuConfig: {
                id_usuario  : null,
                menuUsuario : [],
            },
            departamentos_value : [],
            departamento_id     : null,
            error_depto         : false,
            items_empresas      : [],
            modelo_empresas     : [],
            departamentos_id    : false,
            empresas_id         : false,
            showSucursales          : false,
            error_sucursal          : false,
            sucursalesModel         : [],
            sucursalesItems         : [],
            departamentosItemsUpdate: [],
            sucursalesItemsUpdate   : [],
            abrirModalUpdate        : true,
            itemsDashboard          : [
                {
                    nombre: 'Payroll',
                    value: 'payroll'
                },
                {
                    nombre: 'GDTH',
                    value: 'gdth'
                },
                {
                    nombre: 'Comidas',
                    value: 'comidas'
                }
            ],
            dialogReporte       : false,
            isLoadingModal      : false,
            loadingReporte      : false,
            loadingLottie       : false,
            fraseLoading        : '',

            nombreImagen        : null,
            urlImg              : GetUrlImg.getURL(),

            /* fin variables agregar */
            /* Parametros para camara */
            dialogFoto              : false,
            camera                  : null,
            deviceId                : null,
            devices                 : [],
            imgFoto                 : null,
            isProcessFinished       : false,
            /* Fin parametros para camara */
            /* Parametros para subir foto */
            selectedOption          : null,
            showMenu                : false,
            archivo_adjunto         : "Subir archivo",
            nombreFoto              : null,
            nombreFotoUptade        : null,
            /* Fin parametros para subir foto */

        }
    },
    watch: {
        cliente_value: function(val) {
            if(val != null){
                this.cambiaCliente(val);
            }
        },
        cliente_value_search: function(val) {
            if(val != null){
                this.cambiaCliente(val);
            }
        },
        empresa_value: function(val, oldVal) {
            if(val != null){
                
                if(this.rol_value == 5){
                    if(oldVal != null){
                        this.sucursalesModel = [];
                        this.departamentos_value = [];
                    }
                    this.getSucursalesDeptos(val);
                }
                else {
                    this.cambiaEmpresa(val);
                }
            }
        },
        empresa_value_search: function(val) {
            if(val != null){
                this.cambiaEmpresa(val);
            }
        },
        sucursal_value: function(val) {
            if(val != null){
                this.departamentos_value = [];
                this.cambiaSucursal(val);
            }
        },
        sucursal_value_search: function(val) {
            if(val != null){
                this.cambiaSucursal(val);
            }
        },
        rol_value: function(val, oldVal) {
            if (val == 2) { //admin
                this.empresa_value = null;
                this.sucursal_value = null;
                this.usuario.departamentos = [];
                this.usuario.departamento_id = null;
            } else if (val == 3) { //admin-empresa
                this.sucursal_value = null;
                this.usuario.departamentos = [];
                this.usuario.departamento_id = null;
            } else if (val == 4) { //admin-sucursal
                if(oldVal != null){
                    this.usuario.departamentos = [];
                    this.usuario.departamento_id = null;
                    if(this.rol == "admin-empresa"){
                        this.cambiaEmpresa(this.empresa_value);
                    }
                }
            } else if(val == 6){//empleado
                if(this.rol == "admin-empresa"){
                    this.cambiaEmpresa(this.empresa_value);
                }
            }


            this.showSucursales = (val == 5 ? true:false); 

            if(oldVal != null && val == 5) {//departamento
                if(this.empresa_value !=null){
                    this.getSucursalesDeptos(this.empresa_value);
                    this.sucursalesModel = [];
                    this.departamentos_value = [];
                }
                if(this.rol=="admin-sucursal"){
                    this.cambiaSucursal(this.datosLogin.sucursal_id);
                }
            }
        },
        sucursalesModel: function(val){
            this.departamentos = [];
            for(let suc = 0; suc < this.sucursalesModel.length; suc++){
                let evaluarSucursal = this.sucursalesItems.find(element => element.id == this.sucursalesModel[suc]);
                for(let depto = 0 ; depto < evaluarSucursal.departamentos.length; depto++){
                    this.departamentos.push(evaluarSucursal.departamentos[depto]);
                }
            }

            let departamentos_ids = [];
            for(let valDepto = 0; valDepto < this.departamentos_value.length; valDepto++){
                for(let suc = 0; suc < this.sucursalesModel.length; suc++){
                    let evaluarSucursal = this.sucursalesItems.find(element => element.id == this.sucursalesModel[suc]);
                    for(let depto = 0 ; depto < evaluarSucursal.departamentos.length; depto++){
                        if (evaluarSucursal.departamentos[depto].id == this.departamentos_value[valDepto])
                        departamentos_ids.push(this.departamentos_value[valDepto])
                    }
                }
            }
            this.departamentos_value = departamentos_ids;
        },
        camera: function(id) {
            this.deviceId = id;
        },
        devices: function() {
            const [first, ...tail] = this.devices;
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        },
        nombreImagen: function(newVal, oldVal){
            if(newVal == null || newVal == ''){
                this.$refs.imagen.value = null;
            }
        }
    },
    methods: {
        open(){
            document.getElementById("fileDocument").click();
        },
        listar(parametros = null) {
            this.resetValue();
        },
        eliminar(id, usuario) {
            let self = this;
            Notify.Alert(
                "¿Estás seguro de eliminar a este usuario?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    userApi.deleteUsuario(id).then((response) => {
                        self.$refs.tabla.getData();
                        self.listar();
                        Notify.Success("Usuario eliminado","El usuario ha sido eliminado satisfactoriamente.");
                    })
                    .catch((err) => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            );
        },
        resetPass(data = null) {
            let self = this;

            Notify.Restore(
                null,
                "¿Quieres restaurar contraseña?",
                "Sí. Quiero restaurar",
                () => {
                    self.usuario.password = "123456";
                    self.usuario.primer_inicio_sesion = true;
                    self.usuario.rol_id = self.rol_value;

                    userApi.updatePassword(self.usuario).then((response) => {
                        Notify.Success("Contraseña restaurada","La contraseña se restauró satisfactoriamente.");
                    })
                    .catch((error) => {
                        console.error(err);
                        this.respuesta_error(err);
                    });
                }
            );
        },
        abreModal(accion, data = null, pass = null) {
            this.accion = accion;
            this.obtenerEmpresas();
            if (accion == "add") {
                this.departamentos = [];
                this.roles = [];
                this.imagen = "/static/modal/usuarioCreate.svg";
                this.titulo_modal = "Registrar usuario";
                this.finaliza_parametros();
            } else if (accion == "update") {
                this.imagen = "/static/modal/usuarioUpdate.svg";
                this.titulo_modal = "Actualización de usuario";
                this.usuario.id = data.id;
                this.usuario.nombre = data.nombre;
                this.usuario.apellido_paterno = data.apellido_paterno;
                this.usuario.apellido_materno = data.apellido_materno;
                this.usuario.telefono_oficina = data.telefono_oficina;
                this.usuario.telefono = data.telefono;
                this.usuario.email = data.email;
                this.usuario.email_anterior = data.email;
                this.usuario.usuario = data.usuario;
                this.rol_value = data.rol_id;
                this.usuario.tipo_dashboard = data.tipo_dashboard;

                this.usuario.cliente_id = data.cliente_id;
                this.usuario.empresa_id = data.empresa_id;
                this.usuario.sucursal_id = data.sucursal_id;
                this.usuario.departamento_id = data.departamento_id;

                this.sucursalesItemsUpdate      = data.sucursales;
                this.departamentosItemsUpdate   = data.departamentos;
                
                
                this.nombreFotoUptade = this.urlImg + data.profile_picture;

                /* let depto = [];

                data.usuarioDepartamentos.forEach((element) => {
                    depto.push({
                        id: element["departamento_id"],
                        nombre: element["nombre"],
                    });
                });
                this.usuario.departamentos = depto; */

                /* Comentado por Manuel
                let emp = [];
                data.usuarioEmpresas.forEach((element) => {
                    emp.push({
                        id: element["empresa_id"]
                    });
                });
                this.modelo_empresas = emp; */
                //this.usuario.usuario_empresas = emp;

            }
            if (pass == null) {
                this.getRoles(this.rol, this.accion);
                if (this.rol == "root") {
                    this.getClientes(this.usuario.cliente_id);
                }

                if (this.rol == "admin") {
                    this.cambiaCliente(this.datosLogin.cliente_id,this.usuario.empresa_id);
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.finaliza_parametros();
                    this.finaliza_parametros();
                }

                if (this.rol == "admin-empresa") {
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                    this.getSucursalesDeptos(this.datosLogin.empresa_id);
                    this.finaliza_parametros();
                    this.finaliza_parametros();
                }

                if (this.rol == "admin-sucursal") {
                    this.cambiaSucursal(this.datosLogin.sucursal_id, this.accion);
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                    this.sucursal_value = this.datosLogin.sucursal_id;
                    this.finaliza_parametros();
                    this.finaliza_parametros();
                }

                if (this.rol == "admin-depto") {
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                    this.sucursal_value = this.datosLogin.sucursal_id;
                    this.usuario.departamento_id = this.datosLogin.departamento_id;
                    this.finaliza_parametros();
                    this.finaliza_parametros();
                    this.finaliza_parametros();
                }

                document.getElementById("btnmodal").click();
            } else {
                this.resetPass();
            }
        },
        openModalMenu(data) {
            this.menuConfig.id_usuario = data.id;
            this.imagen = "/static/bg/menu.png";
            this.titulo_modal = "Privilegios";
            this.loading = true;

            //API DE CONSULTA DE MENU DEL USUARIO
            userApi.getMenu(data.id).then(async (response) => {
                this.menuConfig.menuUsuario = await this.createMenuData(this.menu,response);
                this.loading = false;
            })
            .catch((error) => {
                console.error(error);
                Notify.ErrorToast(
                    "Ocurrió un error al cargar el menú del usuario."
                );
            });

            document.getElementById("btnmodalTree").click();
        },
        createMenuData(arrayParent, arrayUser) {
            var newArray = [];

            for (var i = 0; i < arrayParent.length; i++) {
                let arrayChild = [];
                let boolParent = false;
                let itemMenu = arrayParent[i];
                let arrayParentFilter = arrayUser.filter((element) => element.valor == itemMenu.valor);

                if (arrayParentFilter.length > 0) {
                    boolParent = true;
                }

                if (itemMenu.hijos) {
                    for (var y = 0; y < itemMenu.hijos.length; y++) {
                        let itemChild = itemMenu.hijos[y];
                        let boolChild = false;
                        let arrayFilterChild = arrayParentFilter.length > 0 ? arrayParentFilter[0].hijos.filter((element) => itemChild.valor == element.valor) : [];

                        if (arrayFilterChild.length > 0) {
                            boolChild = true;
                        }

                        arrayChild.push({
                            id: itemChild.id,
                            name: itemChild.nombre,
                            valor: itemChild.valor,
                            type: itemChild.tipo,
                            active: boolChild,
                        });
                    }
                }

                newArray.push({
                    id: itemMenu.id,
                    name: itemMenu.nombre,
                    valor: itemMenu.valor,
                    type: itemMenu.tipo,
                    active: boolParent,
                    children: arrayChild,
                });
            }

            return newArray;
        },
        _selectedAll(data, type, index, child) {
            if (type == "M") {
                let newValue = !data.active ? true : false;

                for (let i = 0; i < data.children.length; i++) {
                    let item = data.children[i];
                    item.active = newValue;
                    data.children.splice(i, 1, item);
                }
            } else {
                if (!child.active) {
                    data.active = !false;
                    this.menuConfig.menuUsuario.splice(index, 1, data);
                    return;
                }

                let arrayFilter = data.children.filter(
                    (element) => element.active == false
                );
                if (arrayFilter.length + 1 == data.children.length) {
                    data.active = false;
                    this.menuConfig.menuUsuario.splice(index, 1, data);
                }
            }
        },
        updateMenu() {
            this.loading_guardar = true;
            var params = {
                usuario_id: this.menuConfig.id_usuario,
                vistas: [],
            };

            for (var i = 0; i < this.menuConfig.menuUsuario.length; i++) {
                let itemParent = this.menuConfig.menuUsuario[i];
                if (itemParent.active) {
                    params.vistas.push({ vista_id: itemParent.id });

                    if (itemParent.children) {
                        for (var j = 0; j < itemParent.children.length; j++) {
                        let itemChild = itemParent.children[j];
                        if (itemChild.active) {
                            params.vistas.push({ vista_id: itemChild.id });
                        }
                        }
                    }
                }
            }

            params.vistas = JSON.stringify(params.vistas);
            userApi.updateMenu(params).then(async (response) => {
                this.cerrarModal("cerrar");
                Notify.Success("Menú de usuario", response.message);
            })
            .catch((err) => {
                this.respuesta_error(err);
            });
        },
        async loadModalData() {
            await this.resetValue();
            if (this.rol == "root") {
                this.getClientes();
            } else if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id);
                this.cliente_value_search = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                this.cambiaEmpresa( this.datosLogin.empresa_id);
                this.empresa_value_search =  this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                this.cambiaSucursal(this.datosLogin.sucursal_id);
                this.sucursal_value_search = this.datosLogin.sucursal_id;
            } else if (this.rol == "admin-depto") {
                this.departamento_value_search = this.datosLogin.departamento_id;
            }
            var rol_search = this.rol;
            this.getRoles(rol_search, "busqueda");
        },
        setFilters() {
            let cliente = this.cliente_value_search;
            let empresa = this.empresa_value_search;
            let sucursal = this.sucursal_value_search;
            let departamento = this.departamento_value_search;
            let rol = this.rol_value_search;
            let email = this.correo_search;
            let nombre = this.nombreUsuario;

            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } 
            else if (this.rol == "admin-empresa") {
                empresa = this.datosLogin.empresa_id;
            } 
            else if (this.rol == "admin-sucursal") {
                sucursal = this.datosLogin.sucursal_id;
            } 
            else if (this.rol == "admin-depto") {
                departamento = this.datosLogin.departamento_id;
            }

            let filterParams = {
                cliente_id: cliente,
                empresa_id: empresa,
                sucursal_id: sucursal,
                departamento_id: departamento,
                rol_id: rol,
                email: email,
                nombre: nombre
            };

            let defaultParams = {
                activo: true,
                paginate: true,
                include_padres: true,
            };

            if (filterParams != null) Object.assign(defaultParams, filterParams);

            this.filters = defaultParams;
            this.cliente_value_search = null;
            this.empresa_value_search = null;
            this.sucursal_value_search = null;
            this.departamento_value_search = null;
            this.rol_value_search = null;
            this.correo_search = null;
        },
        getRoles(rol = null, accion) {
            let self = this;
            let roles = [];
            let param = { is_delete: false, paginate: false, order: "asc" };
            rolApi.getRoles(param).then((response) => {
                let respuesta = response.data;
                respuesta.forEach((element) => {
                    if (this.datosLogin.rol.name === "root") {
                        if (
                            element["name"] == "admin" ||
                            element["name"] == "admin-empresa" ||
                            element["name"] == "admin-sucursal" ||
                            element["name"] == "admin-depto" ||
                            element["name"] == "checador" ||
                            (element["name"] == "empleado" &&
                            (accion == "update" || accion == "busqueda"))
                        ) {
                            self.roles.push(element);
                            self.roles_search.push(element);
                        }
                    } 
                    else if (this.datosLogin.rol.name === "admin") {
                        if (
                            element["name"] == "admin-empresa" ||
                            element["name"] == "admin-sucursal" ||
                            element["name"] == "admin-depto" ||
                            element["name"] == "checador" ||
                            (element["name"] == "empleado" &&
                            (accion == "update" || accion == "busqueda"))
                        ) {
                            self.roles.push(element);
                            self.roles_search.push(element);
                        }
                    } 
                    else if (this.datosLogin.rol.name === "admin-empresa") {
                        if (
                            element["name"] == "admin-sucursal" ||
                            element["name"] == "admin-depto" ||
                            element["name"] == "checador" ||
                            (element["name"] == "empleado" &&
                            (accion == "update" || accion == "busqueda"))
                        ) {
                            self.roles.push(element);
                            self.roles_search.push(element);
                        }
                    } 
                    else if (this.datosLogin.rol.name === "admin-sucursal") {
                        if (
                            element["name"] == "admin-depto" ||
                            (element["name"] == "empleado" &&
                            (accion == "update" || accion == "busqueda"))
                        ) {
                            self.roles.push(element);
                            self.roles_search.push(element);
                        }
                    }
                });
                self.finaliza_parametros();
            })
            .catch((err) => {
                console.error(err);
                this.loading_guardar = false;
                Notify.ErrorToast("Ocurrió un error al cargar los roles.");
            });
        },
        getClientes(cliente = null) {
            let self = this;
            let param = { activo: true, paginate: false};
            clienteAPi.getClientes(param).then((response) => {
                this.clientes_search = this.clientes = response.data;
                this.finaliza_parametros();
                if (this.accion == "update") {
                    this.cliente_value = cliente;
                    if (
                        this.rol_value == 3 ||
                        this.rol_value == 4 ||
                        this.rol_value == 5 ||
                        this.rol_value == 6 ||
                        this.rol_value == 7
                    )
                    this.cambiaCliente(cliente, this.usuario.empresa_id);

                    setTimeout(function() {self.finaliza_parametros();}, 300);
                }
            })
            .catch((err) => {
                console.error(err);
                this.loading_guardar = false;
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },
        veeValidate(valido = null) {
            let self = this;
            self.error_depto = false;
            this.$refs.form.validate().then(success => {
                this.loading_guardar = true;
                if(!success) {
                    this.loading_guardar = false;
                    return;
                }
                this.usuario.cliente_id = this.cliente_value;
                this.usuario.empresa_id = this.empresa_value;
                this.usuario.sucursal_id = this.sucursal_value;
                this.usuario.rol_id = this.rol_value;

                if (this.cliente_value == null) delete this.usuario.cliente_id;

                if (this.empresa_value == null) delete this.usuario.empresa_id;

                if (this.sucursal_value == null) delete this.usuario.sucursal_id;

                if (this.rol_value == 5) {
                    if (this.departamentos_value.length == 0) {
                        self.error_depto = true;
                        this.loading_guardar = false;
                        return;
                    }
                    let depto = [];
                    if(this.accion == "add"){
                        this.departamentos_value.forEach((element) => {
                            depto.push({
                                departamento_id: element,
                            });
                        });
                    } 
                    else if(this.accion == "update"){
                        this.departamentos_value.forEach((element) => {
                            depto.push({
                                departamento_id: element,
                            });
                        });
                    }
                    this.usuario.departamentos = depto;
                }

                if (this.rol_value == 6) {
                    this.usuario.departamentos.push(parseInt(this.departamento_id));
                }

                let emp = [];
                if(this.accion == "add"){
                    this.modelo_empresas.forEach((element) => {
                        emp.push({empresa_id:element,})
                    })
                } else if(this.accion == "update"){
                    if(this.empresas_id){
                        this.modelo_empresas.forEach((element) => {
                            emp.push({empresa_id: element,});
                        });
                    }else{
                        this.modelo_empresas.forEach((element) => {
                            emp.push({empresa_id: element["empresa_id"],});
                        });
                    }
                }
                this.usuario.usuario_empresas = emp;

                /* let emp = [];
                this.modelo_empresas.forEach(element =>{emp.push({empresa_id: element,});});
                this.usuario.usuario_empresas = emp; */
                /* if(this.modelo_empresas.length>0){
                    this.modelo_empresas.forEach(element =>{
                        this.usuario.usuario_empresas.push({empresa_id: element});
                    })
                } */



                let formData = new FormData();
                formData.append('nombre', this.usuario.nombre.toString());
                formData.append('apellido_paterno', this.usuario.apellido_paterno.toString());
                if(this.usuario.apellido_materno != null && this.usuario.apellido_materno != ''){
                    formData.append('apellido_materno',this.usuario.apellido_materno.toString());
                }
                if(this.usuario.telefono_oficina != null && this.usuario.telefono_oficina != ''){
                    formData.append('telefono_oficina', this.usuario.telefono_oficina)
                }
                formData.append('telefono',this.usuario.telefono)
                if(this.usuario.email != null && this.usuario.email != ''){
                    formData.append('email',this.usuario.email)
                }
                formData.append('usuario', this.usuario.usuario)
                formData.append('cliente_id', this.usuario.cliente_id)

                if(this.usuario.empresa_id){
                    formData.append('empresa_id', this.usuario.empresa_id)
                }

                if(this.usuario.sucursal_id != null && this.usuario.sucursal_id != ''){
                    formData.append('sucursal_id', this.usuario.sucursal_id)
                }
                
                if(this.usuario.departamento_id != null && this.usuario.departamento_id != ''){
                    formData.append('departamento_id', this.usuario.departamento_id)
                }
                formData.append('rol_id', this.usuario.rol_id)

                if(this.usuario.email_anterior != null && this.usuario.email_anterior != ''){
                    formData.append('email_anterior', this.usuario.email_anterior)
                }
                formData.append('departamentos',JSON.stringify(this.usuario.departamentos))
                formData.append('usuario_empresas', JSON.stringify(this.usuario.usuario_empresas))
                formData.append('tipo_dashboard', this.usuario.tipo_dashboard)
                if (this.archivoImagen != null) {
                    formData.append("profile_picture", this.archivoImagen);
                }



                if (this.accion == "add") {
                    formData.append('password', "123456")

                    userApi.addUsuario(formData).then((response) => {
                        self.$refs.tabla.getData();
                        self.listar();
                        this.cerrarModal();
                        Notify.Success("Registrar usuario","El usuario se guardó satisfactoriamente.");
                    })
                    .catch((err) => {
                        this.loading_guardar = false;
                        this.respuesta_error(err);
                    });
                } 
                else if (this.accion == "update") {
                    formData.append('id', this.usuario.id)

                    userApi.updateUsuario(formData).then((response) => {
                        self.$refs.tabla.getData();
                        self.listar();

                        this.cerrarModal();
                        Notify.Success("Modificación de usuario","El usuario se modificó satisfactoriamente.");
                    })
                    .catch((err) => {
                        this.loading_guardar = false;
                        this.respuesta_error(err);
                    });
                }

            });
        },
        resetValue() {
            this.$nextTick(() => {
                this.titulo_modal = "";
                this.usuario.id = "";
                this.usuario.nombre = "";
                this.usuario.apellido_paterno = "";
                this.usuario.apellido_materno = "";
                this.usuario.telefono_oficina = "";
                this.usuario.email = "";
                this.usuario.usuario = "";
                this.usuario.telefono = "";
                this.usuario.password = "123456";
                this.usuario.cliente_id = null;
                this.usuario.rol_id = null;
                this.usuario.empresa_id = null;
                this.usuario.sucursal_id = null;
                this.usuario.departamento_id = null;
                this.usuario.departamentos = [];
                this.tipo_dashboard = "payroll";
                this.usuario_empresas    = [];
                this.departamentos_id = false;
                this.empresas_id = false;

                this.sucursalesModel            = [];
                this.sucursalesItems            = [];
                this.departamentosItemsUpdate   = [];
                this.sucursalesItemsUpdate      = [];
                this.abrirModalUpdate           = true;

                this.accion = "";
                this.roles = [];
                this.rol_value = null;

                this.clientes = [];
                this.empresas = [];
                this.sucursales = [];
                this.departamentos = [];
                this.carga_final = 0;
                this.finaliza_carga = false;

                this.cliente_value = null;
                this.empresa_value = null;
                this.sucursal_value = null;

                this.usuario.email_anterior = null;
                this.loading_guardar = false;

                this.cliente_value_search = null;
                this.empresa_value_search = null;
                this.sucursal_value_search = null;
                this.departamento_value_search = null;
                this.rol_value_search = null;
                this.nombreUsuario = null;

                this.clientes_search = [];
                this.empresa_search = [];
                this.sucursal_search = [];
                this.departamento_search = [];
                this.error_depto = false;

                this.nombreImagen = null;
                this.archivoImagen = null
                this.nombreFotoUptade = null
            
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }
                
            });
        },
        respuesta_error(err) {
            this.loading_guardar = false;
            if(typeof err.response.data.error === 'string'){
                Notify.ErrorToast(err.response.data.error);
            }
            else{
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }

                Notify.ErrorToast(leyenda);
            }
        },
        cerrarModal(menu = null) {
            this.dialogTree = false;
            this.dialog = false;
            this.dialogBusqueda = false;
            this.loading_guardar = false;
            this.resetValue();
        },
        cambiaCliente(cliente, empresa = null) {
            let param = { activo: true, paginate: false, cliente_id: cliente };
            if(cliente == null){
                return;
            }
            empresaApi.getEmpresas(param).then((response) => {
                this.empresa_search = this.empresas = response.data;
                if (empresa) {
                    this.empresa_value = empresa;
                }
            })
            .catch((err) => {
                console.error(err);
                this.loading_guardar = false;
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },
        cambiaEmpresa(empresa, accion = null) {
            let param = { activo: true, paginate: false, empresa_id: empresa };
            if(empresa==null){
                return;
            }
            sucursalApi.getSucursales(param).then((response) => {
                this.sucursal_search = this.sucursales = response.data;
                if (this.usuario.sucursal_id != null) {
                    this.sucursal_value = this.usuario.sucursal_id;
                }
            })
            .catch((err) => {
                console.error(err);
                this.loading_guardar = false;
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },
        getSucursalesDeptos(empresa, sucursales, departamentos){
            let param = { activo: true, paginate: false, empresa_id: empresa };
            sucursalApi.getSucursalesDepartamentos(param).then((response) => {
                this.sucursalesItems = response;
                if(this.accion == 'update' && this.abrirModalUpdate){
                    this.departamentosItemsUpdate.forEach((element) => {
                        this.departamentos_value.push(element["departamento_id"])
                    })
                    this.sucursalesItemsUpdate.forEach((element) => {
                        this.sucursalesModel.push(element["id"])
                    })

                    this.abrirModalUpdate           = false;
                }
            })
            .catch((err) => {
                console.error(err);
                this.loading_guardar = false;
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },
        cambiaSucursal(sucursal, accion = null) {
             if(sucursal==null){
                return;
            }
            let param = { activo: true, paginate: false, sucursal_id: sucursal };

            departamentoApi.getDepartamentos(param).then((response) => {
                this.departamento_search = this.departamentos = response.data;

                if (this.accion == "update") {
                    if (this.rol_value == 5) {
                        //this.departamentos_value = this.usuario.departamentos;
                    }
                    else if (this.rol_value == 6) {
                        this.departamento_id = this.usuario.departamento_id;
                    }
                    this.usuario.departamentos = [];
                }
            })
            .catch((err) => {
                console.error(err);
                this.loading_guardar = false;
                Notify.ErrorToast("Ocurrió un error al cargar los departamentos.");
            });
        },
        finaliza_parametros() {
            this.carga_final = this.carga_final + 1;
            if (this.carga_final == 3) {
                this.finaliza_carga = true;
            }
        },
        obtenerEmpresas(){
            let param = { activo: true, paginate: false, cliente_id: this.datosLogin.cliente_id };

            empresaApi.getEmpresas(param).then((response) => {
                this.items_empresas = response.data;
            })
            .catch((err) => {
                console.error(err);
                this.loading_guardar = false;
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },
        Cambio_depto(){
            //this.departamentos_id = true;
        },
        Cambio_sucursal(){

        },
        cambio_emp(){
            this.empresas_id = true;
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },
        abrirModalReporte(){
            this.dialogReporte      = true;
            this.loadModalData();
        },
        cerrarModalReporte(){
            this.dialogReporte      = false;
            this.loadingLottie      = false;
            this.fraseLoading       = '';
        },
        descargarReporte(){

            let parametros = { 
                cliente_id  : this.cliente_value_search,
                empresa_id  : this.empresa_value_search,
                sucursal_id : this.sucursal_value_search,
                rol_id      : this.rol_value_search
            }

            if(this.rol == 'root'){
                if(this.cliente_value_search == null || this.cliente_value_search == ''){
                    Notify.ErrorToast("Seleccionar un cliente.");
                    return;
                } 
            }
            else if(this.rol == 'admin'){
                parametros.cliente_id = this.datosLogin.cliente_id;
            }
            else if(this.rol == 'admin-empresa'){
                parametros.cliente_id = this.datosLogin.cliente_id;
                parametros.empresa_id = this.datosLogin.empresa_id;
            }
            else if(this.rol == 'admin-sucursal'){
                parametros.cliente_id = this.datosLogin.cliente_id;
                parametros.empresa_id = this.datosLogin.empresa_id;
                parametros.sucursal_id = this.datosLogin.sucursal_id;
            }
            else if(this.rol == 'admin-depto'){
                parametros.cliente_id = this.datosLogin.cliente_id;
                parametros.empresa_id = this.datosLogin.empresa_id;
                parametros.sucursal_id = this.datosLogin.sucursal_id;
            }
            else{
                parametros.cliente_id = this.datosLogin.cliente_id;
                parametros.empresa_id = this.datosLogin.empresa_id;
                parametros.sucursal_id = this.datosLogin.sucursal_id;
            }

            this.loadingLottie = true;
            this.fraseLoading = 'Descargando';
            const FileDownload = require("js-file-download");
            let nombre;
            nombre = "Reporte_de_usuarios.xlsx";
            let url = "usuarios/reporte-usuarios";
            try {
                Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                    this.cerrarModalReporte();
                })
                .catch(async (error)=>{
                    this.loadingLottie = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar la petición");
                console.log(err);
                this.loadingLottie = false;
            }
        },


        almacenarImagen() {
            this.$refs.imagen.click();
            this.showMenu = false;
            this.validarImagen();
        },

        validarImagen(){
            var self = this;
            //Cuando cambie #avatar
            $("#avatar").change(function(){
                $('#texto').text('');
                $('#img').attr('src', '');
                if(self.validarExtension(this)){
                    if(self.validarPeso(this)){
                        self.verImagen(this);
                    }
                }
            });
        },
        validarExtension(datos){
            //Validacion de estensiones permitidas
            var extensionesValidas = ".png, .gif, .jpeg, .jpg";
            var ruta = datos.value;
            var extension =  ruta.substring(ruta.lastIndexOf('.')+1).toLowerCase();
            var extensionValida = extensionesValidas.indexOf(extension);
            if(extensionValida < 0){
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "Error",
                    html: 'La extensión no es válida <br> Su fichero tiene de extensión: '+extension,
                    showConfirmButton: true,
                    showClass: {
                        popup: 'animated fadeInDown faster'
                    },
                    hideClass: {
                        popup: 'animated fadeOutUp faster'
                    }
                }).then((result) => {
                    if (result.value) {
                        $("#avatar").val('');
                        $("#form").val('');
                        this.nombreImagen=null;
                        this.archivoImagen = null;
                    }
                })
                return false;
            } else {
                return true;
            }
        },
        validarPeso(datos){
            var pesoPermitido = 1536;
            if(datos.files && datos.files[0]){
                var pesoFichero = datos.files[0].size/1024;
                if(pesoFichero > pesoPermitido){
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "Error",
                    html: 'El tamaño de la imagen supera el limite permitido!! <br> El peso maximo permitido del fichero es: ' + pesoPermitido + ' KB Su fichero tiene: ' + pesoFichero + ' KBs',
                    showConfirmButton: true,
                    showClass: {
                        popup: 'animated fadeInDown faster'
                    },
                    hideClass: {
                        popup: 'animated fadeOutUp faster'
                    }
                }).then((result) => {
                    if (result.value) {
                        $("#avatar").val('');
                        $("#form").val('');
                        this.nombreImagen=null;
                        this.archivoImagen = null;
                    }
                })
                    return false;
                }else{
                    return true;
                }
            }
        },
        verImagen(datos){
            if(datos.files && datos.files[0]){
                var reader =  new FileReader();
                reader.onload = function(e){
                $('#img').attr('src',e.target.result);
                };
                reader.readAsDataURL(datos.files[0]);
            }
        },
        omitirFoto() {
            this.imgFoto = null;
            setTimeout(() => {
                this.$refs.webcam.start();
                }, 550);
        },

        cerrarModalFoto(){
            this.dialogFoto = false;
            this.archivo_adjunto = 'Subir archivo';
            setTimeout(() => {
                this.$refs.webcam.stop();
            }, 550);
        },

        guardaFoto(){
            this.$refs.imagen = this.imgFoto;
            $("#img").attr(
                "src",
                this.imgFoto
            );
            this.archivo_adjunto = 'Foto1.jpg';
            //this.empleado.foto = this.imgFoto; //aqui meto la fotos capturada
            this.imgFoto = this.b64toBlob(this.imgFoto);
            const archivo = new FileReader();
                archivo.readAsDataURL(this.imgFoto);
                archivo.addEventListener("load", () => {
                    this.archivoImagen = this.imgFoto;
                });
            this.dialogFoto = false;
        },

        abrirModalFoto(capturar, item = null) {
            setTimeout(() => {
                this.$refs.webcam.start();
            }, 750);
            this.showMenu = false;
            if (capturar == "capturar") {
                document.getElementById("btnModalFoto").click();
            }
            this.imgFoto = null;
        },
        onCapture() {
            this.imgFoto = this.$refs.webcam.capture();
        },
        b64toBlob(base64URL, sliceSize) {
            var arr = base64URL.split(",");
            var contentType = arr[0].match(/:(.*?);/)[1];
            var b64Data = arr[1];
            sliceSize = sliceSize || 512;

            var byteCharacters = atob(b64Data);
            var byteArrays = [];

            for (
                var offset = 0;
                offset < byteCharacters.length;
                offset += sliceSize
            ) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            var blob = new Blob(byteArrays, { type: contentType });
            return blob;
        },

        onStarted(stream) {
            console.log("On Started Event", stream);
        },
        onStopped(stream) {
            console.log("On Stopped Event", stream);
        },
        onStop() {
            this.$refs.webcam.stop();
        },
        onStart() {
            this.$refs.webcam.start();
        },
        onError(error) {
            console.log("On Error Event", error);
        },
        onCameras(cameras) {
            this.devices = cameras;
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
        },
        onFilePickedImagen(evento){
            const img = evento.target.files
            if(img[0] !== undefined){
                this.nombreImagen = img[0].name
                if(this.nombreImagen.lastIndexOf('.') <= 0){
                return
                }
                const archivo = new FileReader()
                archivo.readAsDataURL(img[0])
                archivo.addEventListener('load', () => {
                this.archivoImagen = img[0];
                })
            } else{
                this.nombreImagen = null
                this.archivoImagen = null;
            }
        },

    },
    computed: {
        ...mapState("auth", ["datos", "menu"]),
        device: function() {
            return this.devices.find(n => n.deviceId === this.deviceId);
        },
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        let rol_valor = this.datosLogin.rol.id;
        this.rol_sesion = this.datosLogin.rol.id;
        if (this.rol == "root") {
            this.getClientes();
        } 
        else if (this.rol == "admin") {
            this.cliente_value_search = this.filters.cliente_id = this.datosLogin.cliente_id;
        } 
        else if (this.rol == "admin-empresa") {
            this.empresa_value_search = this.filters.empresa_id = this.datosLogin.empresa_id;
        } 
        else if (this.rol == "admin-sucursal") {
            this.sucursal_value_search = this.filters.sucursal_id = this.datosLogin.sucursal_id;
        } 
        else if (this.rol == "admin-depto" || this.rol == "empleado") {
            this.$router.push("/dashboard");
            Notify.Warning("¡Denegado!","Tu Rol no permite el acceso a esta vista.");
        }
    },
    mounted() {
        this.listar();
    },
    updated() {
        document.querySelector('#tableScroll thead tr th:nth-child(4)').style.width   = "310px";
        document.querySelector('#tableScroll thead tr th:nth-child(4)').style.paddingRight   = "8px";
        document.querySelector('#tableScroll thead tr th:nth-child(4)').style.paddingLeft   = "8px";
    },
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal {
        display: none !important;
    }
    #btnModalReporte {
        display: none;
    }
    .menuClassAcciones{
        /* box-shadow: none; */
        border-radius: 10px!important;
        margin-top:15px;
    }
    .btnAcciones {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }
    .btnAcciones:hover {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        box-shadow: 0px 5px 8px rgba(26, 30, 66, 0.2);
        -webkit-transform: translatey(-1px);
        transform: translatey(-1px);
    }
    .btnAccionesContenedor {
        width: 300px;
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        background-color: #FFF;
    }

    div.btnAccionesTitulo {
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesTitulo span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        color: #1E2245;
    }
    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #F6F6F8;
    }
    .color_rojo {
        color: red;
    }

    .menuClassFoto{
        box-shadow: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }


    /* Estilos subir imagen */
    .btn-group {
        min-width: 80px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }
    .btn-group a:hover {
        text-decoration: none;
    }
    .dropdown-toggle {
        font-family: "Montserrat" !important;
        font-size: 16px !important;
        border: 0.5px solid rgba(0, 0, 0, 0.38);
        box-sizing: border-box;
        border-radius: 8px;
        padding: 9px 45px 8px 15px;
        width: 248px !important;
        height: 56px !important;
        outline: 0;
        color: rgba(0, 0, 0, 0.6);
        min-width: 80px;
        background-color: #FFFFFF;
        transition: background 0s ease-out;
        float: none;
        box-shadow: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        letter-spacing: normal;
        text-align: left;
        line-height: 36px;
    }
    .dropdown-toggle:hover {
        border: 0.5px solid rgba(0, 0, 0, 0.87);
        color: rgba(0, 0, 0, 0.87);
        background: #FFFFFF;
        cursor: pointer;
    }
    .dropdown-menu {
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 1000;
        float: left;
        min-width: 260px;
        padding: 0;
        margin: 0;
        list-style: none;
        font-size: 14px;
        text-align: left;
        background-color: #FFF;
    }
    .dropdown-menu > li > a {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #96999A;
        padding: 10px 30px;
        display: block;
        clear: both;
        white-space: nowrap;
        text-decoration: none;
    }
    .dropdown-menu > li > a:hover {
        background: #96999A;
    }
    .dropdown-menu > li {
        overflow: hidden;
        width: 100%;
        position: relative;
        margin-bottom: -29px;
    }
    .caret {
        color: #96999A;
        font-size: 18px;
        position: absolute;
        top: 7px;
        margin-left: -24px;
        margin-top: 4px;
        vertical-align: middle;
        right: 10px;
        line-height: 36px;
    }
    li {
        list-style: none;
    }
    .textoFoto {
        width: 284px;
        height: 30px;
        left: 218px;
        top: 518px;
        padding-left: 0px 65px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        color: #96999A;
    }
    .btn-contenedor{
        padding-left: 20%;
        padding-right: 20%;
        text-align: center; 
        display: grid;
    }
    .btn-capturar{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.2px;
        display: inline-block !important;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        background: #007EFF !important;
        box-shadow: 0px 8px 16px rgba(0, 126, 255, 0.16), inset 10px 11px 17px rgba(119, 124, 185, 0.11);
        border-radius: 80px;
        text-transform: none;
        margin-bottom: 5px !important;
    }
    .btn-omitir{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: inline-block !important;
        align-items: center;
        text-align: center;
        color: #96999A;
        background: transparent !important;
        border-radius: 80px;
        box-shadow: none !important;
        text-transform: none;
    }
    .imgFoto {
        float: left;
        width: 38px;
        height: 38px;
    }
    .titulo-foto {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #004baf;
        margin-top: 5px !important;
        margin-left: 10px !important;
        float: left;
    }
    /* Fin estilos subir imagen */
    
    .v-btn-cerrar .v-btn:hover::before {
      background-color: transparent !important;
      pointer-events: none;
    }
    .v-btn-cerrar .v-btn:hover::after {
      background-color: transparent !important;
      pointer-events: none;
    }
    .v-btn-cerrar .v-btn:hover {
      background-color: transparent !important;
      pointer-events: none;
    }
    .v-btn-cerrar:hover:before {
      background-color: transparent;
      
    }
    .v-btn-cerrar:before {
        display: none;
    }



</style>


